<template>
  <div>
    <Licences v-if="account.isSubscribed" />
    <Pricing v-else />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Licences from './Licences.vue'
import Pricing from './Pricing.vue'

export default {
  components: { Pricing, Licences },
  computed: {
    ...mapState({
      account: state => state.api.account,
    }),
  },
}
</script>
