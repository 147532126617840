<template>
  <div class="pricing-faq">
    <h3 class="text-center">
      FAQ's
    </h3>
    <p class="text-center">
      Let us help answer the most common questions.
    </p>
    <b-row class="py-2">
      <b-col
        lg="10"
        offset-lg="2"
        class="mx-auto"
      >
        <app-collapse
          accordion
          type="margin"
        >
          <app-collapse-item title="Do I need SalesNavigator to subscribe to Surfe?">
            You do not need a SalesNavigator to use the basic Surfe features.<br>
            Some of the <b>Essential</b> and <b>Pro</b> features can, however, only be used if <i>at least one of your users</i> owns a Sales Navigator licence.<br>
            Visit <a
              href="https://business.linkedin.com/sales-solutions/sales-navigator"
              target="_blank"
            >business.linkedin.com</a> to get started with Sales Navigator.
          </app-collapse-item>

          <app-collapse-item title="Which payment methods do you accept?">
            We accept direct debit or online payments through Visa, MasterCard, and American Express.
          </app-collapse-item>

          <app-collapse-item title="Can I manage my company's Surfe account without using Surfe myself?">
            Sure! Surfe admins are not necessarily Surfe users. Select the number of licences you want to pay for, and assign them to your teammates who will actually use Surfe in the Team panel of this dashboard.
          </app-collapse-item>

          <app-collapse-item title="Does my subscription automatically renew?">
            Yes, it does renew automatically. You can suspend it anytime of course, from your dashboard (by setting the number of licences to 0, or canceling your account), or by reaching out to us via the support chat.
          </app-collapse-item>

          <app-collapse-item title="Can I cancel my subscription at anytime?">
            Yes, you are free to cancel your subscription anytime. Our best price is available as an annual subscription, but we offer monthly subscriptions as well. We do not need a written cancellation of the contract. By switching the subscription off in the dashboard, you have officially canceled your contract with Surfe. To avoid getting charged for the renewal of your licence, make sure you disable your subscription before the expiry date.
          </app-collapse-item>

          <app-collapse-item title="Is there a way to use Surfe on mobile?">
            Surfe is a Google Chrome extension, meaning that it only works with this browser and is unfortunately not compatible with the LinkedIn mobile app.
          </app-collapse-item>
        </app-collapse>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

export default {
  components: {
    AppCollapseItem,
    AppCollapse,
    BCol,
    BRow,
  },
}
</script>
