<template>
  <div>
    <b-modal
      id="assign-seats-modal-with-verification"
      ref="assign-seats-modal-with-verification"
      centered
      size="lg"
      hide-footer
      bordered
      hide-header
      hide-header-close
      no-fade
    >
      <div class="d-flex justify-content-between align-items-center">
        <h4 class="mb-3">
          Assign licences among your team
        </h4>
        <div class="licences-number">
          {{ getLicencesActionText() }}
        </div>
      </div>
      <span class="subtitle">
        Select which members of the team would get a license.
      </span>
      <b-table
        v-if="users.length>0"
        class="mt-40-custom"
        :items="users"
        :fields="tableHeader"
        primary-key="id"
        sticky-header
        outlined
        responsive
        show-empty
        empty-text="No matching records found"
        hover
      >

        <template v-slot:head(paidUser)="data">
          <span>
            {{ data.field.label }}
          </span>
          <feather-icon
            v-b-tooltip.hover.topright="'Assign your purchased license to a desired user'"
            style="margin-left: 8px;"
            variant="outline-primary"
            size="14"
            icon="InfoIcon"
            class="text-muted"
          />
        </template>

        <!-- Column: User -->
        <template #cell(user)="data">
          <b-media style="align-items:center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.pictureURL"
                :text="abbrev(data.item.name)"
                :variant="`light-${colorFromStatus(data.item.status)}`"
              />
            </template>
            <b>{{ data.item.name }}</b>
          </b-media>
        </template>

        <!-- Column: Role -->
        <template #cell(paidUser)="data">
          <b-form-checkbox
            v-model="data.item.isPremium"
            class="custom-control-primary"
            :disabled="!account.isAdmin || (getNumberOfLicencesLeft() <= 0 && !data.item.isPremium)"
            @change="getNumberOfLicencesLeft"
          />
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            v-b-tooltip.hover.top="data.item.status === 'pending' ? 'This user must first download Surfe and connect to their CRM':''"
            pill
            :variant="`light-${colorFromStatus(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.status }}
            <feather-icon
              v-if="data.item.status === 'pending'"
              variant="outline-primary"
              size="14"
              icon="InfoIcon"
            />
          </b-badge>
        </template>
      </b-table>

      <div class="d-flex justify-content-end mt-40-custom mb-6">
        <b-button
          v-b-modal.modal-invite
          variant="white"
          class="mr-2"
          style="min-width: 150px;"
        >
          <feather-icon
            icon="PlusIcon"
            size="20"
            class="mr-2"
          />
          <span>Invite Team Member</span>
        </b-button>
        <b-button
          v-b-tooltip.hover.top="users.length > getNumberOfLicencesLeft() ? 'Remaining licences will be automatically assigned to users': ''"
          variant="primary"
          :disabled="isButtonDisabled() || waitForLicences"
          @click="showVerificationCodeModal"
        >
          <feather-icon
            icon="UserIcon"
            size="20"
            class="mr-2"
          />
          <b-spinner
            v-if="waitForLicences"
            class="mx-2"
            small
          />
          <span v-if="!waitForLicences">Assign Licences</span>
        </b-button>
      </div>
    </b-modal>

    <InviteMemberModal />
    <VerificationCodeModal
      ref-id="assign-seats-modal-verification-code"
      @submit="assignLicences"
    />
  </div>
</template>

<script>

import { mapState } from 'vuex'
import {
  BAvatar, BBadge, BButton, BFormCheckbox, BMedia, BModal, BSpinner, BTable, VBTooltip,
} from 'bootstrap-vue'
import InviteMemberModal from '@/views/team/components/InviteMemberModal.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { trackEvent } from '@core/utils/tracking'
import VerificationCodeModal from '@/views/billing/VerificationCodeModal.vue'
import { hasValidVerificationToken } from '@/utils'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    VerificationCodeModal,
    BSpinner,
    InviteMemberModal,
    BButton,
    BBadge,
    BMedia,
    BFormCheckbox,
    BTable,
    BAvatar,
    BModal,
  },
  props: {
    licences: {
      type: Number,
      default: 0,
    },
    plan: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    const tableHeader = [
      {
        key: 'user',
        label: 'Surfe User',
      },
      {
        key: 'email',
        label: 'Email',
      },
      {
        key: 'paidUser',
        label: 'Paid User',
      },
      {
        key: 'status',
        label: 'Status',
      },
    ]

    return {
      tableHeader,
      waitForLicences: false,
    }
  },
  computed: {
    ...mapState({
      account: state => state.api.account,
      users: state => state.api.users,
      namespace: state => state.api.account.namespace,
      tiered: state => state.api.account.tiered,
    }),
  },
  methods: {
    showVerificationCodeModal() {
      if (hasValidVerificationToken()) {
        this.assignLicences()
        return
      }
      this.$bvModal.show('assign-seats-modal-verification-code')
    },
    async assignLicences() {
      try {
        this.waitForLicences = true
        if (this.plan) {
          await this.updateLicences()
        }

        let updatedUsers = this.users

        if (this.getNumberOfLicencesLeft() > 0) {
          const autoAssignedLicenseUsers = this.users.filter(user => !user.isPremium).map(user => user.id).slice(0, this.getNumberOfLicencesLeft())

          updatedUsers = this.users.map(user => {
            if (autoAssignedLicenseUsers.includes(user.id)) {
              return { ...user, isPremium: true }
            }
            return user
          })
        }

        // Stripe's API needs some time to return the result,
        // that's why we wait 1000ms in order to get the correct number of licences.
        setTimeout(async () => {
          await this.$store.dispatch('api/updateUsersSecure', { users: updatedUsers })
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Licences updated',
              icon: 'UsersIcon',
              variant: 'success',
            },
          })
          await this.$store.dispatch('api/loadAccount')
        }, 1000)
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'An error occurred',
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
        })
        throw error
      } finally {
        this.$bvModal.hide('assign-seats-modal-with-verification')
        this.waitForLicences = false
      }
    },
    async updateLicences() {
      if (this.licences === this.plan.licences) {
        this.$bvModal.hide('assign-seats-modal-with-verification')
        return
      }

      await this.$store.dispatch('api/updateSubscription', {
        quantity: this.licences,
        Namespace: this.namespace ? this.namespace : 'leadjet',
        Tiered: this.tiered,
      })
      await trackEvent({ type: 'dashboard-billing-licenses_number_edited', props: { count: this.licences } })
    },
    getNumberOfLicencesLeft() {
      if (!this.users.length) return this.licences
      return Math.abs(this.licences - this.users.filter(x => x.isPremium).length)
    },
    abbrev(name) {
      return name.split(' ').map(n => n[0]).join('')
    },
    colorFromStatus(status) {
      switch (status) {
        case 'pending':
          return 'warning'
        case 'admin':
          return 'info'
        case 'active':
          return 'success'
        default:
          return 'secondary'
      }
    },
    getLicencesActionText() {
      if (!this.users.length) return 0
      if (this.licences >= this.users.filter(x => x.isPremium).length) {
        return `Available licences: ${this.getNumberOfLicencesLeft()}`
      }

      return `Licences to remove: ${this.getNumberOfLicencesLeft()}`
    },
    hasPremiumUser() {
      return this.users.some(user => user.isPremium)
    },
    isButtonDisabled() {
      const isRemoveAction = this.getLicencesActionText().toLocaleLowerCase().includes('remove')

      if (isRemoveAction) return this.getNumberOfLicencesLeft() > 0
      return this.getNumberOfLicencesLeft() > 0 && !this.hasPremiumUser()
    },
  },
}
</script>
<style scoped>
.subtitle{
  color: #5A6C71;
  font-size: 14px;
  margin-bottom:49px;
}

.mt-40-custom {
  margin-top: 40px;
}

.licences-number {
  background-color: #EBFAFE;
  color: #387082;
  padding: 4px 10px;
  border-radius: 4px;
}
</style>
