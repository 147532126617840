<template>
  <div>
    <h4 class="card-title">
      Payment Method
    </h4>

    <div
      v-if="paymentMethods && paymentMethods.length > 0"
      class="text-muted"
    >
      <p v-if="paymentMethods[0].card">
        The credit card <b>{{ paymentMethods[0].card.brand.toUpperCase() }}</b> ending with <b>{{ paymentMethods[0].card.last4 }}</b> is currently in use for this account.
      </p>

      <p v-else-if="paymentMethods[0].sepa_debit">
        SEPA transfer is currently in use for this account.
      </p>
    </div>
    <p v-else>
      No payment method linked to this account.
    </p>

    <p>
      <a
        class="font-weight-bolder text-info text-lighten-1"
        @click="openModal"
      >Change your payment method</a>
    </p>

    <!-- Modal update payment method -->
    <b-modal
      id="modal-payment-method"
      ref="modal-payment-method"
      title="Change your payment method"
      hide-footer
      hide-header
      hide-header-close
      centered
    >
      <h3>Select a payment method</h3>
      <p class="checkout-details-subtitle">
        Choose a payment method of your liking.
      </p>

      <!-- Accordion Menu -->
      <div role="tablist">
        <b-card
          no-body
          style="border-bottom: 1px solid #E0E5E6;"
          class="mb-1 accordion"
        >
          <b-card-header
            v-b-toggle.sepa-accordion
            header-tag="header"
            class="p-1"
            role="tab"
            @click="toggleCheckbox('sepa')"
          >
            <div class="d-flex p-2">
              <checkbox
                :checked="checked.sepa"
                label="SEPA Payment"
                :disabled="true"
              />

            </div>
            <span>
              <feather-icon
                v-if="checked.sepa"
                icon="ChevronUpIcon"
                size="15"
              />
              <feather-icon
                v-else
                icon="ChevronDownIcon"
                size="15"
              />
            </span>
          </b-card-header>
          <b-collapse
            id="sepa-accordion"
            :visible="isEurope"
            accordion="checkout-accordion"
            role="tabpanel"
          >
            <b-card-body class="pt-0">
              <b-card-text>
                <img
                  class="mb-1"
                  src="@/assets/images/powered_stripe.png"
                  style="width: 90px"
                  alt="powered_stripe"
                >
                <div id="sepa-element" />
                <div id="sepa-element-errors" />
              </b-card-text>
            </b-card-body>
          </b-collapse>
        </b-card>

        <b-card
          no-body
          class="mb-1 accordion"
        >
          <b-card-header
            v-b-toggle.card-accordion
            header-tag="header"
            class="p-1"
            role="tab"
            @click="toggleCheckbox('card')"
          >
            <div class="d-flex p-2">
              <checkbox
                :checked="checked.card"
                label="Credit / Debit Card"
                :disabled="true"
              />
            </div>
            <span>
              <feather-icon
                v-if="checked.card"
                icon="ChevronUpIcon"
                size="15"
              />
              <feather-icon
                v-else
                icon="ChevronDownIcon"
                size="15"
              />
            </span>
          </b-card-header>
          <b-collapse
            id="card-accordion"
            :visible="!isEurope"
            accordion="checkout-accordion"
            role="tabpanel"
          >
            <b-card-body class="pt-0">
              <b-card-text>
                <img
                  class="mb-1"
                  src="@/assets/images/powered_stripe.png"
                  style="width: 90px"
                  alt="powered_stripe"
                >
                <div id="card-element" />
                <div id="card-element-errors" />
              </b-card-text>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
      <!-- Accordion Menu End -->

      <div class="d-flex justify-content-end mt-10 position-absolute buttons-pos">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="white"
          class="mr-2 cancel-button"
          :disabled="waitForPaymentMethod"
          @click="closeModal()"
        >
          <span>Cancel</span>
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          :disabled="waitForPaymentMethod || !eventComplete"
          @click="savePaymentMethod"
        >
          <b-spinner
            v-if="waitForPaymentMethod"
            class="mx-2"
            small
          />
          <span v-else>Save</span>
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  VBModal,
  BButton,
  BSpinner,
  BModal,
  BCollapse,
  BCard,
  BCardText,
  BCardBody,
  BCardHeader,
} from 'bootstrap-vue'
import { mapState } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import Ripple from 'vue-ripple-directive'
import Checkbox from '@core/components/checkbox/Checkbox.vue'

const { authHeaders, sleep } = require('../../../utils')

// eslint-disable-next-line no-undef
const stripe = Stripe(process.env.VUE_APP_STRIPE_KEY)

export default {
  components: {
    BModal, BButton, BSpinner, BCollapse, BCard, BCardText, BCardBody, BCardHeader, Checkbox,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  data() {
    const isEurope = (Intl.DateTimeFormat().resolvedOptions().timeZone)?.toLowerCase()?.includes('europe')
    return {
      waitForPaymentMethod: false,
      eventComplete: false,
      isEurope,
      checked: {
        sepa: isEurope,
        card: !isEurope,
      },
    }
  },
  computed: {
    ...mapState({
      paymentMethods: state => state.api.paymentMethods,
      customer: state => state.api.customer,
      account: state => state.api.account,
    }),
  },
  methods: {
    async savePaymentMethod() {
      this.waitForPaymentMethod = true
      try {
        const pmType = this.checked.card ? 'card' : 'sepa_debit'

        // Get payment intent
        const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/admin/checkout/pm/setup?pmType=${pmType}`, authHeaders())

        // Setup payment method
        if (pmType === 'card') {
          await this.setupCardPaymentMethod(data.id)
        } else {
          await this.setupSepaPaymentMethod(data.id)
        }

        // Update account's payment method on success
        await this.$store.dispatch('api/updatePaymentMethod', pmType)

        await this.$store.dispatch('api/loadPaymentMethods')
        this.checked = {
          sepa: true,
          card: false,
        }
        this.$refs['modal-payment-method'].hide()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'New payment method added',
            icon: 'SaveIcon',
            variant: 'success',
          },
        })
        this.waitForPaymentMethod = false
      } catch (error) {
        this.waitForPaymentMethod = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Failed to Save Payment Method',
            text: error.message ?? null,
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
        }, { timeout: false })
        throw error
      }
    },
    async setupCardPaymentMethod(setupIntentId) {
      const result = await stripe.confirmCardSetup(setupIntentId, {
        payment_method: {
          card: this.card,
          billing_details: {
            name: this.customer.name ?? this.account.name,
          },
        },
      })
      if (result.error) throw result.error
      return result.paymentMethod
    },
    async setupSepaPaymentMethod(setupIntentId) {
      const result = await stripe.confirmSepaDebitSetup(setupIntentId, {
        payment_method: {
          sepa_debit: this.sepa,
          billing_details: {
            name: this.customer.name || this.account.name,
            email: this.customer.email || this.account.email, // email is required for SEPA transfers
          },
        },
      })
      if (result.error) throw result.error
      return result.paymentMethod
    },
    async createCheckoutForm() {
      // Wait for card element
      for (let i = 0; i < 15; i++) {
        await sleep(100)
        if (document.getElementById('card-element')) break
      }
      if (!document.getElementById('card-element')) return

      const elements = stripe.elements()

      // Card Element styles
      const style = {
        base: {
          fontSize: '16px',
          color: '#32325d',
          fontFamily: '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif',
          fontSmoothing: 'antialiased',
          '::placeholder': {
            color: '#a0aec0',
          },
        },
      }

      this.card = elements.create('card', { style })
      this.card.mount('#card-element')

      this.card.on('focus', () => {
        const el = document.getElementById('card-element-errors')
        el.classList.add('focused')
      })

      this.card.on('blur', () => {
        const el = document.getElementById('card-element-errors')
        el.classList.remove('focused')
      })

      this.card.on('change', event => {
        const displayError = document.getElementById('card-element-errors')
        if (event.error) {
          displayError.textContent = event.error.message
        } else {
          displayError.textContent = ''
        }

        this.eventComplete = event.complete
      })

      // Populate sepa section with stripe elements
      this.sepa = elements.create('iban', { style, supportedCountries: ['SEPA'] })
      this.sepa.mount('#sepa-element')

      this.sepa.on('change', async event => {
        const displayError = document.getElementById('sepa-element-errors')
        displayError.textContent = ''
        if (event.error) {
          displayError.textContent = event.error.message
          return
        }

        this.eventComplete = event.complete
      })
    },
    openModal() {
      this.$refs['modal-payment-method'].show()
      this.createCheckoutForm()
    },
    closeModal() {
      this.$refs['modal-payment-method'].hide()
    },
    toggleCheckbox(paymentType) {
      this.checked = {
        sepa: false,
        card: false,
        [paymentType]: !this.checked[paymentType],
      }

      // Disable save button, because the user selected a different payment method
      this.eventComplete = false

      // Reset current payment method to empty, if the user selected a different payment method
      if (paymentType === 'sepa') {
        this.sepa.update({ value: '' })
        return
      }
      this.card.update({ value: '' })
    },
  },
}
</script>
<style lang="scss">
  #modal-payment-method > div {
    max-width: 555px;
    height: 593px;

    &> div {
      height: 400px;
    }
  }

  .checkout-details-subtitle {
    color: #889BA0;
    font-size: 14px;
    margin-bottom: 35px;
  }

  .cancel-button {
    color:#5C818A
  }
</style>
