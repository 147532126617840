<template>
  <section v-if="Object.keys(plan).length">
    <div class="text-center">
      <h3 style="margin-bottom: 14px; font-size: 36px">
        Pricing Plans
      </h3>
      <p
        class="text-info text-lighten-2"
        style="font-size: 14px"
      >
        Pay monthly or yearly, and cancel at any time
      </p>

      <b-tabs
        align="center"
        pills
        nav-class="mb-3"
      >
        <b-tab
          style="position:relative"
          :active="period === 'monthly'"
          @click="switchPeriod('monthly')"
        >
          <template #title>
            Monthly Billing
            <b-img
              v-if="period === 'monthly' && account.crm === 'COPPER'"
              :src="require('@/assets/images/up-to-40-discount-img.png')"
              alt="40-percent-copper"
              style="position: absolute;right: 0;left: 183%; width: 187px;"
            />
            <b-img
              v-if="period === 'monthly' && account.crm !== 'COPPER'"
              :src="require('@/assets/images/up-to-25-discount-img.png')"
              alt="great-choice"
              style="position: absolute;right: 0;left: 183%; width: 187px;"
            />
          </template>
          <Plans
            ref="plans"
            :plan="plan"
            :period="period"
            :currency="currency"
            :namespace="plan.namespace"
            :tiered="plan.tiered"
            :licences="licences"
            :show-currency-switch="true"
          />
        </b-tab>
        <b-tab
          style="position:relative"
          :active="period === 'yearly'"
          @click="switchPeriod('yearly')"
        >
          <template #title>
            Yearly Billing
            <img
              v-if="period === 'yearly'"
              src="@/assets/images/great-choice.png"
              alt="great-choice"
              style="position: absolute;right: 0;left: 92%"
            >
          </template>
          <Plans
            ref="plans"
            :plan="plan"
            :period="period"
            :currency="currency"
            :namespace="plan.namespace"
            :tiered="plan.tiered"
            :licences="licences"
            :show-currency-switch="true"
          />
        </b-tab>
      </b-tabs>
    </div>

    <div style="max-width:1500px; margin:0 auto">

      <!-- Currency switch -->

    </div>

    <!-- apply promo code -->
    <b-row
      v-if="account.isAdmin && hasTrialPlan"
      class="pricing-free-trial mt-4 rounded bg-white border-1 border-secondary border-darken-1"
    >
      <b-col
        lg="10"
        class="mx-auto"
      >
        <div class="pricing-trial-content d-flex justify-content-center justify-content-md-between">
          <div class="text-center text-md-left my-8">
            <h3>Have a promo code to extend your trial?</h3>
            <b-form
              class="d-flex align-items-center"
              @submit.prevent
            >
              <b-form-input
                v-model="promoCode"
                class="mr-2"
                placeholder="Promo Code"
              />
              <b-button
                variant="primary"
                class="my-2"
                @click="applyPromoCode"
              >
                Apply
              </b-button>
            </b-form>
          </div>
        </div>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        md="10"
        class="mx-auto"
      >
        <Invoices />
      </b-col>
    </b-row>

    <FAQ class="my-24" />

    <!-- Book a demo -->
    <b-row class="pricing-free-trial my-24 rounded bg-white border-1 border-secondary border-darken-1">
      <b-col
        lg="10"
        class="mx-auto"
      >
        <div class="pricing-trial-content d-flex justify-content-center justify-content-md-between">
          <div class="text-center text-md-left mt-14">
            <h3 class="text-primary">
              Still not convinced? Let's talk about it!
            </h3>
            <p class="text-info text-lighten-2 font-weight-bold">
              Book a demo with the Surfe team.
            </p>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="my-14"
              href="https://calendly.com/d/m5cb-656y/30-minute-leadjet-demo"
              target="_blank"
              @click="trackBookDemo"
            >
              Book a demo
            </b-button>
          </div>

          <div
            class="d-none d-md-block position-absolute position-top-0 position-bottom-0 position-right-0"
            style="width:343px"
          >
            <b-img
              fluid
              :src="require('@/assets/images/image-backdrop.svg')"
              class="pricing-trial-img trial-img-backgrop position-absolute"
              alt="svg img"
            />
            <b-img
              fluid
              :src="require('@/assets/images/people-surfing-illustration.png')"
              :srcset="require('@/assets/images/people-surfing-illustration.png') + ' 1x, ' + require('@/assets/images/people-surfing-illustration@2x.png') + ' 2x'"
              class="pricing-trial-img position-absolute zindex-1"
              alt="svg img"
            />
          </div>
        </div>
      </b-col>
    </b-row>
    <!--/ pricing free trial -->
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormInput,
  BImg,
  BButton,
  VBTooltip,
  BTab,
  BTabs,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapState } from 'vuex'
import { trackEvent } from '@core/utils/tracking'
import Invoices from '@/views/billing/components/Invoices.vue'
import FAQ from './components/FAQ.vue'
import Plans from './components/Plans.vue'

/* eslint-disable global-require */
export default {
  components: {
    Invoices,
    BButton,
    BForm,
    BFormInput,
    BRow,
    BTab,
    BTabs,
    BCol,
    BImg,
    FAQ,
    Plans,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    const isInEU = !!this.$store.state.appConfig.layout.isInEU
    return {
      period: 'yearly',
      plan: { selected: 'essential', price: 0 },
      enrichment: { credits: 0, price: 0 },
      licences: 1,
      currency: isInEU ? 'eur' : 'usd',
      promoCode: '',
    }
  },
  computed: {
    ...mapState({
      account: state => state.api.account,
      users: state => state.api.users,
      hasTrialPlan: state => {
        const companyPlan = state.api.account?.companyPlan
        return companyPlan === 'TRIAL'
      },
    }),
  },
  watch: {
    users() {
      if (this.users) this.licences = this.users.length
    },
  },
  async mounted() {
    if (this.users) this.licences = this.users.length
    window.addEventListener('beforeunload', this.closingWindow)
    await trackEvent({ type: 'billing-screen_4-viewed' })
  },
  methods: {
    async closingWindow() {
      await trackEvent({ type: 'billing-screen_4-window_closed' })
    },
    async trackBookDemo() {
      await trackEvent({ type: 'dashboard-billing-book_demo_clicked' })
    },
    async switchPeriod(period) {
      if (this.period !== period) {
        await trackEvent({ type: 'dashboard-billing-yearly_toggled', props: { isYearly: period === 'yearly' } })
      }
      this.period = period
    },
    async applyPromoCode() {
      if (!this.promoCode || this.promoCode.length < 3) {
        return this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Invalid promo code',
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
        })
      }

      try {
        await this.$store.dispatch('api/applyPromoCode', this.promoCode)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Promo code applied',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
        this.promoCode = ''
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Invalid promo code',
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
        })
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-pricing.scss";
@import '~@core/scss/base/bootstrap-extended/include'; // Bootstrap includes

.sticky-pricing {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  background-color: white;
  box-shadow: 0 0 15px 0 rgb(34 41 47 / 5%);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 1rem 1rem 260px;
}

.pricing-trial-img {
  bottom: map-get($spacers, 11);

  &.trial-img-backgrop {
    bottom: map-get($spacers, 8);
    left: 50%;
    transform: translateX(-50%);
  }
}

.price-list {
  padding: 0.5em 1.5em;
  min-width: 180px;
}

.separator-price-left {
  border-left: 1px solid rgb(220, 225, 235);
}

.border-white {
  border: 1px solid white !important;
}

.list-group-item {
  padding: 0.4rem 0;
}

.list-group-item:after {
  top: 0.8rem !important;
}

.nav-pills > li {
  position: relative;
}
</style>
