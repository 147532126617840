<template>
  <div
    class="failed-payment-div"
  >
    <p class="m-0 p-0">
      {{ info.message }}
      <b-link
        :href="info.href"
      >
        {{ info.hrefMessage }}
      </b-link>
    </p>
  </div>
</template>

<script>
import { BLink } from 'bootstrap-vue'

export default {
  components: {
    BLink,
  },
  props: {
    info: {
      required: true,
      type: Object,
      default: () => {},
    },
  },
}
</script>
<style scoped>
   .failed-payment-div {
     display: flex;
     justify-content: center;
     margin-top: 10px;
     margin-bottom: 10px;
     border-radius: 5px;
     background-color: rgba(255, 117, 143, 0.12);
     color:#FF758F;
     padding: 10px;
   }
</style>
