<template>
  <div
    style="max-width:1000px;padding-bottom:100px"
    class="mx-auto"
  >
    <payment-error
      v-if="paymentIntentError"
      :info="{
        message: 'The payment of your last invoice did not succeed, please update your payment method.',
      }"
    />

    <!-- Current plan -->
    <b-card
      v-if="plan.name"
      title="Current plan"
      class="p-1"
    >
      <b-row v-if="customer.subscriptions">
        <!-- Plan -->
        <b-col
          md="4"
          class="price-list"
        >
          <h5 class="text-info text-lighten-2 mb-0">
            Plan
          </h5>

          <p class="font-medium-4 font-weight-bold cap my-4">
            {{ plan.name }}
          </p>

          <p class="mb-0">
            <router-link
              class="font-weight-bolder text-info text-lighten-1"
              to="/change-plan"
            >Switch plan</router-link>
          </p>
        </b-col>

        <!-- Number of licences -->
        <b-col
          md="4"
          class="price-list separator-price-left"
        >
          <h5 class="text-info text-lighten-2 mb-0">
            Number of licences
          </h5>

          <!-- This is a special case only for GOOGLE-->
          <!-- check https://linear.app/surfe/issue/DEV-5221/stripe-keeps-resetting-number-of-licenses for more details-->
          <p
            v-if="account.companyKey !== 'GSHEETSGOOGLE.COM'"
            class="font-medium-4 font-weight-bold my-4"
          >
            {{ plan.licences }}
          </p>
          <p
            v-else
            class="font-medium-4 font-weight-bold cap my-4"
          >
            Custom
          </p>

          <p
            v-if="account.companyKey !== 'GSHEETSGOOGLE.COM'"
            class="mb-0"
          >
            <a
              v-b-modal.modal-licences
              class="font-weight-bolder text-info text-lighten-1"
            >Add/Remove seats</a>
          </p>
        </b-col>

        <!-- Period -->
        <b-col
          md="4"
          class="price-list separator-price-left"
        >
          <h5 class="text-info text-lighten-2 mb-0">
            Billing Period
          </h5>

          <p class="font-medium-4 font-weight-bold cap my-4">
            {{ plan.period }}
          </p>

          <p class="mb-0">
            <a
              v-b-modal.modal-period
              class="font-weight-bolder text-info text-lighten-1"
            >Change period</a>
          </p>
        </b-col>
      </b-row>
    </b-card>

    <!-- Billing details & Payment method -->
    <b-card
      v-if="customer.address"
      class="p-1"
    >

      <h4 class="card-title">
        Billing details
      </h4>

      <p class="text-muted">
        <b>{{ customer.name }}</b><br><br>
        {{ customer.email }}<br>
        {{ customer.address.line1 }},
        {{ customer.address.postal_code }},
        {{ customer.address.city }},
        {{ customer.address.country }}<br>
        {{ vatNumber }}
      </p>

      <p>
        <a
          v-b-modal.modal-billing-details
          class="font-weight-bolder text-info text-lighten-1"
        >Update your billing details</a>
      </p>
      <p>
        <a
          v-b-modal.modal-retry-payment
          class="font-weight-bolder text-info text-lighten-1"
        >Retry Payment</a>
      </p>

      <hr class="my-8">

      <PaymentMethod />
    </b-card>

    <Invoices />

    <!-- Modal add/remove seats -->
    <b-modal
      id="modal-licences"
      ref="modal-licences"
      centered
      hide-footer
      hide-header
      hide-header-close
    >
      <h4 class="mb-3">
        Edit Licences
      </h4>
      <p class="edit-licences-subtitle">
        Choose the total number of paying licences for your account.
      </p>
      <p class="number-of-seats-text">
        Current number of seats : <b>{{ plan.licences }}</b>
      </p>

      <b-form-spinbutton
        v-model="licences"
        min="0"
        max="10000"
        class="my-3 spinbutton"
        @change="updateLicences"
      />
      <div class="billed-info">
        You will be billed for the change after submitting.
      </div>

      <div class="d-flex justify-content-end position-absolute buttons-pos">
        <b-button
          variant="white"
          class="mr-2 cancel-button"
          @click="closeModal('modal-licences')"
        >
          <span>Cancel</span>
        </b-button>
        <b-button
          class="submit-button"
          :disabled="waitForLicences || licences === plan.licences"
          variant="primary"
          @click="openModalByName('modal-licences', isGodMode() ? 'assign-seats-modal' : 'assign-seats-modal-with-verification')"
        >
          <b-spinner
            v-if="waitForLicences"
            class="mx-2"
            small
          />
          <span v-else>
            <span v-if="licences > plan.licences">Add {{ licences - plan.licences }} licences</span>
            <span v-else-if="licences < plan.licences">Remove {{ plan.licences - licences }} licences</span>
            <span v-else>No change</span>
          </span>
        </b-button>
      </div>
    </b-modal>

    <!-- Modal change period -->
    <b-modal
      id="modal-period"
      ref="modal-period"
      title="Change billing period"
      centered
      hide-footer
    >
      <p>
        You are currently billed <b class="text-primary">{{ plan.period }}</b>.<br>
        Choose your new billing cycle.
      </p>

      <div class="d-flex align-items-center justify-content-center my-3">
        <h6 class="mr-1 mb-0">
          Monthly
        </h6>

        <b-form-checkbox
          v-model="period"
          name="price-switch"
          value="yearly"
          unchecked-value="monthly"
          switch
        />

        <h6 class="ml-2 mb-0">
          Yearly
        </h6>
      </div>

      <b-alert
        variant="primary"
        show
      >
        <div class="alert-body">
          You will be billed for the change after submitting
        </div>
      </b-alert>

      <b-button
        style="float:right"
        :disabled="waitForPeriod"
        :variant="period === plan.period ? 'outline-primary' : 'primary'"
        @click="updatePeriodVerification"
      >
        <b-spinner
          v-if="waitForPeriod"
          class="mx-2"
          small
        />
        <span v-else>
          <span v-if="period !== plan.period">Switch to {{ period }} billing</span>
          <span v-else>No change</span>
        </span>
      </b-button>
    </b-modal>

    <!-- Modal update billing details -->
    <b-modal
      id="modal-billing-details"
      ref="modal-billing-details"
      centered
      hide-footer
      hide-header
      hide-header-close
    >
      <h3>Check your billing details</h3>
      <p class="checkout-details-subtitle">
        Your billing details will appear on your invoices.
      </p>

      <BillingDetails
        ref="billing"
      />

      <div class="d-flex justify-content-end position-absolute buttons-pos">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="white"
          class="mr-2 cancel-button"
          :disabled="waitForBillingDetails"
          @click="closeModal('modal-billing-details')"
        >
          <span>Cancel</span>
        </b-button>
        <b-button
          style="float:right"
          variant="primary"
          :disabled="waitForBillingDetails"
          @click="updateBillingDetails"
        >
          <b-spinner
            v-if="waitForBillingDetails"
            class="mx-2"
            small
          />
          <span v-else>
            Save
          </span>
        </b-button>
      </div>
    </b-modal>

    <!-- Confirm retry payment modal -->
    <b-modal
      id="modal-retry-payment"
      ref="modal-retry-payment"
      title="Retry Payment?"
      centered
      cancel-variant="outline-primary"
      ok-title="Confirm"
      :ok-disabled="isRetryPaymentLoading"
      @ok="confirmRetryPayment"
      @cancel="closeModal('modal-retry-payment')"
    >
      <template>
        <p>We will attempt to process the previous payment again using the billing information you have provided.</p>
        <div />
      </template>
    </b-modal>

    <AssignSeatsModalWithVerification
      v-if="!isGodMode()"
      :licences="licences"
      :plan="plan"
    />
    <AssignSeatsModal
      v-if="isGodMode()"
    />

    <VerificationCodeModal
      ref-id="modal-period-verification"
      @submit="updatePeriod"
    />
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BAlert, BButton, BSpinner, BFormSpinbutton, BFormCheckbox, VBTooltip, VBModal,
} from 'bootstrap-vue'
import { mapState } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import PaymentError from '@/views/billing/components/PaymentError.vue'
import VerificationCodeModal from '@/views/billing/VerificationCodeModal.vue'
import AssignSeatsModalWithVerification from '@/views/billing/AssignSeatsModalWithVerification.vue'
import AssignSeatsModal from '@/views/billing/AssignSeatsModal.vue'
import BillingDetails from './components/BillingDetails.vue'
import Invoices from './components/Invoices.vue'
import PaymentMethod from './components/PaymentMethod.vue'

const { isGodMode, hasValidVerificationToken } = require('../../utils')

export default {
  components: {
    AssignSeatsModal,
    AssignSeatsModalWithVerification,
    VerificationCodeModal,
    PaymentError,
    BCard,
    BRow,
    BCol,
    BAlert,
    BButton,
    BSpinner,
    BFormSpinbutton,
    BFormCheckbox,
    Invoices,
    BillingDetails,
    PaymentMethod,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      licences: 4,
      period: 'monthly',
      waitForLicences: false,
      waitForPeriod: false,
      waitForBillingDetails: false,
      isRetryPaymentLoading: false,
    }
  },
  computed: {
    ...mapState({
      account: state => state.api.account,
      customer: state => state.api.customer,
      namespace: state => state.api.account.namespace,
      tiered: state => state.api.account.tiered,
      plan: state => {
        const subscription = state.api.customer.subscriptions?.data[0] || {}
        if (!subscription.plan) return {}

        let id = 'basic'

        if (subscription.plan.id.includes('enrich')) id = 'enrichment'
        else if (subscription.plan.id.includes('starter')) id = 'starter'
        else if (subscription.plan.id.includes('professional')) id = 'professional'
        else if (subscription.plan.id.includes('pro_')) id = 'pro'
        else if (subscription.plan.id.includes('essential')) id = 'essential'
        else if (subscription.plan.id.includes('entreprise')) id = 'entreprise'
        else if (subscription.plan.id.includes('business')) id = 'business'

        const PLAN_NAME = {
          ESSENTIAL: 'Essential', BASIC: 'Basic', PRO: 'Pro', TRIAL: 'Trial', FREE: 'Free',
        }

        return {
          id,
          name: PLAN_NAME[state.api.account.accountPlan] || id,
          period: subscription.plan.id.includes('monthly') ? 'monthly' : 'yearly',
          licences: subscription.quantity,
        }
      },
      paymentIntentError() {
        return this.$store.getters['api/getPaymentIntentError']
      },
    }),
    vatNumber() {
      if (!this.customer.tax_ids || this.customer.tax_ids.data.length === 0) return ''
      return this.customer.tax_ids.data[0].value
    },
    currencySymbol() { return this.customer.currency === 'eur' ? '€' : '$' },
  },
  mounted() {
    this.licences = this.account.licences
    if (!this.account.isAdmin) this.$router.push('/')
  },
  methods: {
    isGodMode,
    async confirmRetryPayment() {
      try {
        this.isRetryPaymentLoading = true
        await this.$store.dispatch('api/retryStripePayment')

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Successful payment retry',
            icon: 'UserIcon',
            variant: 'success',
          },
        })
        setTimeout(async () => {
          await Promise.all([
            this.$store.dispatch('api/loadCustomer'),
            this.$store.dispatch('api/loadAccount'),
            this.$store.dispatch('api/loadUsers'),
            this.$store.dispatch('api/loadPaymentIntent'),
            this.$store.dispatch('api/loadPaymentMethods')])
          this.account.isSubscribed = true
        }, 1500)
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'An error occurred',
            text: err.message ?? null,
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isRetryPaymentLoading = false
        this.closeModal('modal-retry-payment')
      }
    },
    closeModal(ref) {
      this.$refs[ref].hide()
    },
    updatePeriodVerification() {
      if (this.period === this.plan.period) {
        this.$refs['modal-period'].hide()
        return
      }

      if (hasValidVerificationToken() || isGodMode()) {
        this.updatePeriod()
        return
      }
      this.$bvModal.show('modal-period-verification')
    },
    async updatePeriod() {
      this.waitForPeriod = true
      try {
        await this.$store.dispatch('api/updateSubscription', {
          Plan: this.plan.id,
          Frequency: this.period,
          Currency: this.customer.currency,
          Namespace: this.namespace ? this.namespace : 'leadjet',
          Tiered: this.tiered,
        })

        this.$refs['modal-period'].hide()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Billing period updated',
            icon: 'SaveIcon',
            variant: 'success',
          },
        })
      } catch (error) {
        console.error(error)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'An error occurred',
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
        })
      }
      this.period = this.plan.period
      this.waitForPeriod = false
    },
    async updateBillingDetails() {
      this.waitForBillingDetails = true
      try {
        await this.$refs.billing.update()

        this.$refs['modal-billing-details'].hide()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Billing details updated',
            icon: 'SaveIcon',
            variant: 'success',
          },
        })
      } catch (error) {
        console.error(error)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'An error occurred',
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
        })
      }
      this.waitForBillingDetails = false
    },
    openModalByName(hideName, showName) {
      this.$bvModal.hide(hideName)
      this.$bvModal.show(showName)
    },
    async updateLicences() {
      await this.$store.dispatch('appState/updateSelectedLicences', this.licences)
    },
  },
}
</script>

<style lang="scss">
.cap {
  text-transform:capitalize;
}

#modal-billing-details > div {
  max-width: 555px;
  height: 593px;
}

#modal-billing-details > div > div {
  height: 610px;
}

.checkout-details-subtitle {
  color: #889BA0;
  font-size: 14px;
  margin-bottom: 35px;
}

#modal-licences {
  margin: 17px 0;

  &> div {
    width: 478px;
    height: 483px;

    &> div {
      height: 483px;

      &> div {
        padding: 30px
      }
    }
  }
}

.edit-licences-subtitle {
  color: #5A6C71;
  font-size: 14px;
  margin-bottom:49px;
}

.number-of-seats-text {
  font-size: 16px;
  color: #49575B;
  margin-bottom: 17px;
}

.buttons-pos{
  bottom: 30px;
  right: 30px;
}

.submit-button {
  min-width: 190px;
  width: 100%;
}

.cancel-button {
  color:#5C818A
}

.spinbutton {
  background-color: #EFFBFE !important;
  border: 1.2px solid #73C7E1 !important;
  height: 107px;
  border-radius: 4px;

  &> output {
    display: flex;
    height: 75px;
    font-size: 40px;
    justify-content: center;
    align-items: center;
    border-left: 1.2px solid #73C7E1 !important;
    border-right: 1.2px solid #73C7E1 !important;
  }

  &> button {
    width: 106px
  }

  &> button > svg {
    width: 40px;
    height: 29px;
  }
}

.billed-info {
  font-size: 14px;
  color: #3A4446;
  font-weight: 600;
}
</style>
