<template>
  <b-card
    title="Invoices"
    class="p-1"
  >
    <b-card-text class="text-muted mb-8">
      All your past invoices are listed below. You can view and download them by clicking the <b>View</b> link to the right.
    </b-card-text>

    <b-table
      :fields="fields"
      :items="invoices"
      responsive="sm"
      class="my-3"
    >
      <template #cell(date)="data">
        <b>{{ data.item.period_end | timestamp }}</b>
      </template>

      <template #cell(number)="data">
        <span class="text-info text-lighten-2">
          {{ data.item.number }}
        </span>
      </template>

      <template #cell(amount)="data">
        {{ data.item.currency | currency }}
        {{ data.item.amount_paid | amount }}
      </template>

      <template #cell(status)="data">
        <b-badge
          :variant="getInvoiceColor(data.item)"
          class="py-2 px-4"
        >
          {{ getInvoiceStatus(data.item) }}
        </b-badge>
      </template>

      <template #cell(view)="data">
        <a
          :href="data.item.hosted_invoice_url"
          target="_blank"
          class="text-info text-lighten-2"
        >View</a>
      </template>
    </b-table>

    <b-card-text class="text-muted mt-8">
      Please note that our prices are VAT exclusive. VAT will be billed to EU customers in line with the applicable rates of their member state unless a valid VAT number is provided.
    </b-card-text>
  </b-card>
</template>

<script>
import {
  BCard, BCardText, BTable, BBadge,
} from 'bootstrap-vue'
import { mapState } from 'vuex'

export default {
  components: {
    BCard,
    BCardText,
    BTable,
    BBadge,
  },
  filters: {
    timestamp(value, ns = false) {
      if (!value) return ''
      // eslint-disable-next-line no-param-reassign
      if (!ns) value *= 1000
      return new Date(value).toLocaleDateString(undefined, { day: 'numeric', year: 'numeric', month: 'long' })
    },
    currency(value) {
      if (value === 'eur') return '€'
      if (value === 'usd') return '$'
      return ''
    },
    amount(value) { return parseFloat(value) / 100 },
  },
  data() {
    return {
      fields: [
        'date', 'number', 'amount', 'status', 'view',
      ],
      items: [
        {
          date: 'dfdf',
          invoiceNumber: 'GG-420',
          amount: 78.52,
          status: 'paid',
          view: 'http://google.fr',
        }, {
          date: 'dfdf',
          invoiceNumber: 'GG-420',
          amount: 78.52,
          status: 'paid',
          view: 'http://google.fr',
        }, {
          date: 'dfdf',
          invoiceNumber: 'GG-420',
          amount: 78.52,
          status: 'paid',
          view: 'http://google.fr',
        },
      ],
    }
  },
  computed: {
    ...mapState({
      invoices: state => state.api.invoices,
    }),
  },
  methods: {
    getInvoiceStatus(invoice) {
      if (invoice.charge?.refunded) return 'Refunded'
      return invoice.paid ? 'Paid' : 'Pending'
    },
    getInvoiceColor(invoice) {
      if (invoice.charge?.refunded) return 'light-info'
      if (!invoice.paid) return 'light-warning'
      return 'light-success'
    },
  },
}
</script>
